<template>
  <div id="app">
    <router-view ref="routerView"/>
  </div>
</template>

<script>
// import Header from "@/components/Header";
// import Invitation from './views/Invitation.vue'
// import Home from './views/Home.vue'

export default {
  name: 'App'
}
</script>

<style lang="scss">
@import "~@/assets/universal.scss";
@import url("https://fonts.googleapis.com/css2?family=Poiret+One&display=swap");  

</style>
